import { StateCreator, create } from "zustand";
import { WalletsEntity } from "./wallet.types";

interface WalletState {
  wallets: WalletsEntity;
  isLoading: boolean;
  error: string | null;
}

interface WalletActions {
  setWallets: (wallets: WalletsEntity) => void;
  getWallets: () => WalletsEntity;
  clearWallets: () => void;
  setError: (error: string | null) => void;
  setLoading: (isLoading: boolean) => void;
}

export type CreateWalletStoreState = WalletState & WalletActions;

export const createWalletStore =
  (initialData: { wallets: WalletsEntity }): StateCreator<CreateWalletStoreState> =>
  (set, get) => ({
    wallets: initialData.wallets || [],
    isLoading: false,
    error: null,

    setWallets: (wallets: WalletsEntity) => {
      if (!wallets) {
        set({ error: "Invalid wallet data" });
        return;
      }

      set({ wallets, error: null });
    },

    getWallets: () => get().wallets,

    clearWallets: () => set({ wallets: [] }),

    setError: (error: string | null) => set({ error }),

    setLoading: (isLoading: boolean) => set({ isLoading }),
  });

export const useWalletStore = create<CreateWalletStoreState>(createWalletStore({ wallets: [] }));
