import { getDefaultConfig } from "connectkit";
import { createConfig } from "wagmi";
import { mainnet, polygon } from "wagmi/chains";

const web3config = createConfig(
  getDefaultConfig({
    // Your dApps chains
    chains: [mainnet, polygon],

    // Required API Keys
    walletConnectProjectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID!,

    // Required App Info
    appName: "Cypher Dashboard",

    // Optional App Info
    appDescription: "Monitor your crypto portfolio in real-time",
    appUrl: "https://cypherdashboard.com",
    appIcon: "/favicon.ico",
  })
);

export default web3config;
