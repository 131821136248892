"use client";

import { useEffect } from "react";
import { useWalletStore } from "./wallet.store";
import { getWallets } from "./wallet.api";

export function WalletProvider({ children }: { children: React.ReactNode }) {
  const { setWallets, setLoading, setError } = useWalletStore();

  useEffect(() => {
    const fetchWallets = async () => {
      try {
        setLoading(true);
        const wallets = await getWallets();
        setWallets(wallets);
      } catch (error) {
        setError(error instanceof Error ? error.message : "Failed to fetch wallets");
      } finally {
        setLoading(false);
      }
    };

    fetchWallets();
  }, [setWallets, setLoading, setError]);

  return <>{children}</>;
}
