import(/* webpackMode: "eager", webpackExports: ["ProtectedProvider"] */ "/app/src/app/providers/protected.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/app/providers/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SheetMenu"] */ "/app/src/shared/libs/shadcn/components/admin-panel/sheet-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserNav"] */ "/app/src/shared/libs/shadcn/components/admin-panel/user-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeModeToggle"] */ "/app/src/widgets/global/ThemeModeToggle.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/global/UserInterfaceLayout.tsx");
