"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { WagmiProvider } from "wagmi";
import { mainnet } from "wagmi/chains";
// import { useState, useEffect } from "react";

import web3config from "./web3provider.config";
import { WalletProvider } from "@/entities/wallet/wallet.provider";
import { ConnectKitProvider } from "connectkit";
import darkTheme from "./connectkit-dark-theme.json";
import lightTheme from "./connectkit-light-theme.json";
import { useTheme } from "next-themes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // Data stays fresh for 5 minutes
      refetchOnWindowFocus: false, // Disable automatic refetch on window focus
    },
  },
});

export function ProtectedProvider({ children }: { children: React.ReactNode }) {
  const { theme, systemTheme } = useTheme();

  // Determine the effective theme
  const currentTheme = theme === "system" ? systemTheme : theme;

  // TODO: Web3 modal doesn't re-render when theme changes. Fix this
  // Force re-render of ConnectKitProvider when theme changes
  // const [mounted, setMounted] = useState(false);

  // useEffect(() => {
  //   setMounted(true);
  // }, []);

  // if (!mounted) {
  //   return null;
  // }

  return (
    <WagmiProvider
      config={web3config}
      initialState={{
        chainId: mainnet.id,
        connections: new Map(),
        current: null,
        status: "disconnected",
      }}
    >
      <QueryClientProvider client={queryClient}>
        <WalletProvider>
          <ConnectKitProvider
            options={{
              embedGoogleFonts: true,
            }}
            customTheme={currentTheme === "dark" ? darkTheme : lightTheme}
          >
            {children}
          </ConnectKitProvider>
        </WalletProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </WagmiProvider>
  );
}
